import React from "react"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Text } from "../components/styled/Styled"
import { MEDIA_QUERIES, COLORS, FONT_FAMILIES } from "../constants"

const Section = styled(motion.div)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 800px;
  justify-content: center;
  background-color: ${COLORS.GREY};
  article {
    width: 50%;
  }

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column-reverse;
    height: 100%;

    article {
      width: 100%;
    }
  }
`

const Article = styled.article`
  padding: 4rem;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    padding: 2rem;
  }

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 100%;
  }
`

const ImageContainer = styled.div`
  width: 50%;
  overflow: hidden;
  position: relative;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 100%;
    height: 300px;
  }
`

const Img = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: center;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (max-width: ${MEDIA_QUERIES.SMALL}) {
  }
`

const Name = styled.p`
  text-transform: uppercase;
  margin: 0;
  color: ${COLORS.SECONDARY};
  font-family: ${FONT_FAMILIES.BUTTON};
  font-size: 3rem;
  line-height: 3rem;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    font-size: 2rem;
    line-height: 2rem;
  }
`

const Role = styled(motion.p)`
  text-align: right;
  font-size: 2.6rem;
  text-transform: uppercase;
  color: ${COLORS.SECONDARY};
  margin: 0;
  margin-bottom: 0.5rem;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    margin-top: 1.5rem;
  }
`

const Underlined = styled.div`
  width: 40%;
  height: 2px;
  background-color: ${COLORS.SECONDARY};
  margin-bottom: 2rem;
  margin-top: 0.75rem;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 100%;
  }
`

const Images = styled(motion.div)`
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  transform-origin: 50% 0;
  article {
    width: 30%;
    height: 750px;
    padding: 1rem 1.5%;
  }

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    flex-direction: column;
    article {
      width: 100%;
      height: 500px;
      padding: 1rem 1.5%;
    }
  }
`

const DancerTemplate = ({
  data: {
    markdownRemark: { frontmatter: dancer },
  },
}) => {
  return (
    <Layout>
      <SEO title="Dancers" />
      <Container>
        <Role
          initial={{ opacity: 0, x: "400px" }}
          animate={{
            opacity: 1,
            x: 0,
            transition: {
              delay: 1,
              duration: 0.75,
              ease: "easeOut",
              type: "spring",
              damping: 50,
            },
          }}
        >
          {dancer.role}
        </Role>
        <Section
          initial={{ opacity: 0, y: 400 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              delay: 0.5,
              duration: 0.75,
              ease: "easeOut",
              type: "spring",
              damping: 50,
            },
          }}
        >
          <Article>
            <Name>{dancer.firstname}</Name>
            <Name>{dancer.lastname}</Name>
            <Underlined />
            <Text style={{ fontSize: "1.2rem" }}>{dancer.description}</Text>
          </Article>
          <ImageContainer>
            <Img fluid={dancer.featuredimage.childImageSharp.fluid} />
          </ImageContainer>
        </Section>
        <Images
          initial={{ opacity: 0, x: "-400px" }}
          animate={{
            opacity: 1,
            x: 0,
            transition: {
              delay: 1.75,
              duration: 1,
              ease: "easeOut",
            },
          }}
        >
          <article>
            <Img fluid={dancer.image1.childImageSharp.fluid} />
          </article>
          <article>
            <Img fluid={dancer.image2.childImageSharp.fluid} />
          </article>
          <article>
            <Img fluid={dancer.image3.childImageSharp.fluid} />
          </article>
        </Images>
      </Container>
    </Layout>
  )
}

export default DancerTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        firstname
        lastname
        role
        description
        featuredimage {
          childImageSharp {
            fluid(quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image1 {
          childImageSharp {
            fluid(quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
